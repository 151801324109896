<template>
    <!-- 导航 -->
    <div class="hander">
        <page-head title="伤病报告"></page-head>
        <div v-if="playerData.showList && playerData.showList.length > 0" class="total">
            <span class="text">累计伤病人次 / 当前伤病人数</span>
            <div class="num">
                <span class="akrobatBold">{{ injuryTotal }}</span>
                /
                <span class="current akrobatBold">{{ currentInjury }}</span>
            </div>
        </div>
    </div>
    <!-- 列表 -->
    <player-list :list="playerData.showList" pageType="injury" @player-click="playerClick"/>
    <!-- 翻页 -->
    <pagination-com
        v-if="playerData.showList && playerData.showList.length > 0"
        class="pagination"
        :current-page="playerData.page"
        :total-page="playerData.totalPage"
        @changePage="changePage"
    />
</template>

<script>
import {useRouter, useRoute, onBeforeRouteLeave} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch, computed} from 'vue';
import {useStore} from "vuex";

export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            playerData: {}, // 页面数据
            serverData: {}, // 服务端数据
            query: {
                pageNum: 1,
                pageSize: 12
            },
            injuryTotal: '0',
            currentInjury: '0',
        });

        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.playerData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: playerClick
            })
        })

        /** 获取球员列表 **/
        const getData = async () => {
            const {id} = store.state.teamData;
            let params = {}
            if (route.query.type === 'team') {
                params['teamId'] = id
            } else if (route.query.type === 'camp') {
                params['campId'] = id
            }
            let {
                code,
                data: {
                    player,
                    injuryTotal,
                    currentInjury
                }
            } = await (route.query.type === 'camp' ? proxy.$server.campInjuryList(params) : proxy.$server.injuryList(params));

            if (code === 200) {
                const {pageNum, pageSize} = state.query;
                state.serverData = player;
                state.injuryTotal = injuryTotal;
                state.currentInjury = currentInjury;
                state.playerData = proxy.$utils.paginationFn(player, pageNum, pageSize);
            }
        };

        const changePage = (page) => {
            const {pageSize} = state.query;
            state.playerData = proxy.$utils.paginationFn(state.serverData, page, pageSize);
        }

        const playerClick = (row) => {
            router.push({
                path: '/injuryReport/details',
                query: {
                    id: row.id,
                    name: row.name
                }
            })
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        onMounted(() => {
            if (queryParams.value.page) {
                state.query.pageNum = queryParams.value.page
            }
            getData();
        });

        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.pageNum
            }
            if (to.name === 'injuryReportDetails') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })

        return {
            ...toRefs(state),
            changePage,
            playerClick,
        }
    },
})
</script>

<style scoped lang="scss">
.hander {
    position: relative;
    width: 100%;

    .total {
        position: absolute;
        top: 35px;
        right: 475px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 28px;
        height: 78px;
        background: linear-gradient(180deg, rgba(111, 81, 174, 0.4) 70.68%, rgba(102, 70, 169, 0.4) 100%);
        backdrop-filter: blur(12px);
        border-radius: 45px;
        text-align: center;

        .text {
            font-size: 25px;
            line-height: 35px;
            color: #fff;
            opacity: 0.8;
        }

        .num {
            margin-left: 24px;
            color: #fff;

            span {
                font-size: 56px;
            }

            .current {
                color: #FF4235;
            }
        }
    }
}

.pagination {
    margin-left: 590px;
}
</style>